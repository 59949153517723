@media print {
  @page { margin: 10mm; }
  body {
    /* size: 50mm 150mm; */
    /* margin: 35mm 35mm 35mm 35mm; */
  }
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  .divFooter {
  
  }
  div#ugetWitnesses {
    margin-top: 200px;
  }
  #lastPagess {
    margin-top: 200px;
    
  }
  div#closureEventFooter {
    margin-top: 70px;
    width: 100%;
  }
  div#closureContractEventFooter {
    margin-top: 150px;
    width: 100%;
  }
  .mt-report {
    margin-top: 90px;
  }
}

@page {
  /* size: 8in 10.25in; */
  /* margin: 0mm 0mm 27mm 0mm; */
}

.table-fs-litle {
  font-size: 10px;
}

div#closureEventFooter p {
  margin: 0px;
  font-size: 12px;
}
div#closureContractEventFooter p {
  margin: 0px;
  font-size: 12px;
}